import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Token } from '@angular/compiler';

interface TokenResponseDto {
  status: string;
  accessToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private router = inject(Router);
  private localStorageService = inject(LocalStorageService);

  private isLoading$$ = new BehaviorSubject<boolean>(true);
  private currentAuthentication$$: BehaviorSubject<string | null>;

  constructor(
    private http: HttpClient,
  ) {
    this.currentAuthentication$$ = new BehaviorSubject<string | null>(
      this.localStorageService.getAuthentication(),
    );
    this.isLoading$$.next(false);
  }

  get isLoading$() {
    return this.isLoading$$.asObservable();
  }

  get currentAuthentication$() {
    return this.currentAuthentication$$.asObservable();
  }

  get currentAccessTokenValue() {
    return this.currentAuthentication$$.value;
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading$$.next(isLoading);
  }

  login(username: string, password: string) {
    return this.http
      .post<TokenResponseDto>(
        environment.wolframUrl + '/labVAuthentication',
        {
          username: username,
          password: password,
          rememberMe: true,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
          params: new HttpParams().set('_key', 'wolfram123'),
        },
      )
      .pipe(
        map(
          (tokenResponse) => {
            console.log(tokenResponse);
            if (tokenResponse && tokenResponse.status == 'Success') {
              console.log("SUCCESS!");
              this.localStorageService.setAuthentication(tokenResponse.accessToken);
              this.currentAuthentication$$.next(tokenResponse.accessToken);
            }
          },
        ),
      );
  }

  logout() {
    this.localStorageService.removeAuthentication();
    this.currentAuthentication$$.next(null);
    this.router.navigate(['/']).then();
  }

  isAuthenticated() {
    return this.localStorageService.getAuthentication() !== null;
  }
}
