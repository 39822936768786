<footer
  class="footer grid grid-cols-1 place-items-center gap-4 bg-primary p-4 text-base-content md:grid-cols-2 md:p-10 lg:grid-cols-3"
>
  <aside class="place-items-center">
    <img
      class="w-24 md:w-48"
      ngSrc="assets/images/logos/labv_logo_inverted.png"
      width="500"
      height="350"
      alt="LabV Intelligent Solutions"
      priority
    />
  </aside>

  <nav class="text-center text-white">
    <p class="mx-auto">&copy; {{ year }} LabV Intelligent Solutions GmbH</p>
    <p class="mx-auto pb-3">
      Frankenthaler Stra&szlig;e 20, 81539 Munich, Germany
    </p>
    <p class="mx-auto">
      <a class="link-hover link" [href]="'footer-privacy-url' | transloco">{{
          'footer-privacy-text' | transloco
        }}</a>
      &nbsp;
      <a class="link-hover link" [href]="'footer-imprint-url' | transloco">{{
          'footer-imprint-text' | transloco
        }}</a>
    </p>
  </nav>
</footer>
