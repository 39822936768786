import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvironmentInformationComponent } from '../../environment-information/environment-information.component';
import { NavbarComponent } from '../../navbar/navbar.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EnvironmentInformationComponent, NavbarComponent],
})
export class HeaderComponent {

  environment = environment;

  constructor() {}

}
