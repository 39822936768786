import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { AuthService } from 'src/core/services/auth.service';
import { AvatarComponent } from '../avatar/avatar.component';
import { DropdownComponent } from '../elements/dropdown/dropdown.component';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-user-profile-menu',
  templateUrl: './user-profile-menu.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoPipe, DropdownComponent, AvatarComponent, FormsModule],
})
export class UserProfileMenuComponent {
  @ViewChild(DropdownComponent) dropdownComponent!: DropdownComponent;

  userAvatarUrl = '/assets/images/avatars/johndoe.png';
  wolframUrl: string;

  constructor(
    public readonly router: Router,
    public readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService,
  ) {
    this.wolframUrl = this.localStorageService.getWolframUrl();
  }

  signOut() {
    this.authService.logout();
    this.dropdownComponent.closeDropdown();
  }

  updateWolframUrl() {
    this.localStorageService.setWolframUrl(this.wolframUrl);
    alert("Wolfram URL set to " + this.wolframUrl);
  }
}
