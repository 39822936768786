import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component, Input,
} from '@angular/core';
import { LanguageSelectComponent } from '../language-select/language-select.component';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, LanguageSelectComponent, NgOptimizedImage],
})
export class AvatarComponent {

  @Input () src: string | undefined;
  @Input () circleClasses: string = 'h-10 w-10';
  @Input () textClasses: string = '';

  constructor() {
  }

}
