import { Routes } from '@angular/router';
import { appRoutesNames } from './app.routes.names';
import { authGuard } from '../../core/guards/auth.guard';
import { AssistantComponent } from '../components/app/assistant/assistant.component';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: appRoutesNames.ASSISTANT,
  },
  {
    path: appRoutesNames.AUTH,
    loadChildren: () =>
      import('./auth/auth.routes').then((module) => module.authRoutes),
  },
  {
    path: appRoutesNames.ASSISTANT,
    canMatch: [authGuard],
    component: AssistantComponent,
    title: 'LabV Assistant',
  },
  {
    path: appRoutesNames.ASSISTANT + '/:conversationUuid',
    canMatch: [authGuard],
    component: AssistantComponent,
    title: 'agent',
  },
  {
    path: appRoutesNames.ASSISTANT + '/:conversationUuid/:threadUuid',
    canMatch: [authGuard],
    component: AssistantComponent,
    title: 'agent',
  },
  {
    path: '**',
    loadChildren: () =>
      import('./not-found/not-found.routes').then(
        (module) => module.notFoundRoutes,
      ),
  },
];
