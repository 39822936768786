<div
  class="avatar placeholder flex items-center justify-center rounded-full hover:bg-base-300"
>
  <div
    class="mx-auto border-1 border-gray-300 bg-gray-200 text-neutral-content rounded-full {{ circleClasses }}"
  >
    @if (src) {
      <img
        alt="Avatar"
        ngSrc="{{src}}"
        width="200"
        height="200"
      />
    } @else {
      <span class="{{ textClasses }}">
        JD
      </span>
    }
  </div>
</div>
