<app-dropdown
  [dropdownClass]="'dropdown-end relative flex space-y-2'"
  [buttonClass]="'btn btn-ghost h-12 w-12 rounded-full hover:bg-base-300'"
  [contentClass]="'card w-72 top-12 z-10 bg-base-100 shadow-2xl'"
>
  <ng-container dropdown-button>
    <app-avatar src="{{userAvatarUrl}}" />
  </ng-container>
  <ng-container dropdown-content>
    <div class="card-body gap-4">
      <app-avatar
        src="{{userAvatarUrl}}"
        class="mx-auto"
        circleClasses="w-20 h-20"
        textClasses="text-3xl"
      />

      <div
        class="grid grid-cols-[auto_1fr] items-center gap-x-4 gap-y-1 text-xs"
      >
        <input type="text" [(ngModel)]="wolframUrl" value="{{ wolframUrl }}" class="input input-bordered input-sm flex-grow">
        <button class="btn btn-primary btn-sm p-0 h-8 w-8" (click)="updateWolframUrl()">&#10003;</button>
      </div>
      <div class="flex flex-col gap-2">
        <button (click)="signOut()" class="btn btn-primary gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8m4-9-4-4m4 4-4 4m4-4H9"
            />
          </svg>
          {{ 'profile-card-logout-button' | transloco }}
        </button>
      </div>
    </div>
  </ng-container>
</app-dropdown>
