import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { TitleStrategy, provideRouter } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';
import { appRoutes } from './routes/app.routes';
import { TranslocoHttpLoader } from '../transloco-loader';
import { CustomTitleStrategy } from '../core/services/title-strategy.service';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideTransloco({
      config: {
        availableLangs: ['en', 'de'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    importProvidersFrom(
      LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    ),
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          renderer: {
            table(header: string, body: string) {
              return `<table class="table table-zebra table-xs">${header}${body}</table>`;
            }
          },
          breaks: true,
          tables: true,
        },
      },
    }),
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
  ],
};
