import { inject } from '@angular/core';
import {
  CanMatchFn,
  Route,
  Router,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const authGuard: CanMatchFn = (
  _route: Route,
  urlSegments: UrlSegment[],
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const authService = inject(AuthService);

  if (!authService.isAuthenticated()) {
    return inject(Router).parseUrl('/auth/login');
  }
  return true;
};
