import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  standalone: true,
})
export class IconComponent {

  @Input() icon!: string;

  @Input() width: number = 20;
  @Input() height: number = 20;

  @Input() fill: string = "";

  constructor() {
  }
}
