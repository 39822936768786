import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
  OnInit, AfterViewInit, OnDestroy, HostListener,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { AvatarComponent } from '../../shared/avatar/avatar.component';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { NGXLogger } from 'ngx-logger';
import { AsyncPipe, Location } from '@angular/common';
import { MarkdownComponent } from 'ngx-markdown';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { _ReadyStateEvent, SSE, SSEHeaders, SSEvent } from 'sse.js';
import { LangChainThreadDto } from './dto/langchain/langchain.thread.dto';
import { LangChainDocumentDto } from './dto/langchain/langchain.document.dto';
import { IconComponent } from '../../shared/icon/icon.component';
import { appRoutesNames } from '../../../routes/app.routes.names';
import { KatexOptions } from 'ngx-markdown';

interface StreamEvent extends SSEvent {
  headers: SSEHeaders;
}

interface WolframToolRequest {
  Name: string;
  Parameters: {
    [key: string]: string;
  };
}

interface WolframToolResponse {
  Name: string;
  Parameters: {
    [key: string]: string;
  };
  Output: never;
}

interface WolframMessage {
  Role: string;
  Content: string;
  Timestamp: string;
  Suggestions?: string[];
  ToolRequest?: WolframToolRequest;
  ToolResponse?: WolframToolResponse;
  Documents?: LangChainDocumentDto[];
}

interface WolframConversation {
  messages: WolframMessage[];
  conversationID: string;
  conversationName: string;
}

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoPipe, AvatarComponent, FormsModule, AsyncPipe, MarkdownComponent, NgxJsonViewerModule, IconComponent],
})
export class AssistantComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('messageInput') messageInputElement!: ElementRef;
  @ViewChild('conversationBox') conversationBoxElement!: ElementRef;

  private conversationUuid = '-';
  private threadUuid = '-';

  private readonly observer: MutationObserver;
  private dateSectionRegExp = /\|\|(.*?)\|\|/gm;

  private chatHistory: WolframMessage[] = [];
  chatHistory$ = new BehaviorSubject<WolframMessage[]>(this.chatHistory);
  processing$ = new BehaviorSubject<boolean>(false);
  isDocumentMode$ = new BehaviorSubject<boolean>(false);
  userMessage = '';

  userAvatarUrl = '/assets/images/avatars/johndoe.png';
  assistantAvatarUrl = '/assets/images/avatars/otto.png';

  private wolframKey = 'wolfram123';
  private wolframUser = 'devlabv1';

public optionsKatex: KatexOptions = {
   delimiters: [
     {left: "$$", right: "$$", display: true},
     {left: "\\(", right: "\\)", display: false},
     {left: "\\[", right: "\\]", display: true},
   ]
 };

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private logger: NGXLogger,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.observer = new MutationObserver(() => {
      this.scrollToBottom();
      this.setFocusOnInput();
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.conversationUuid = params['conversationUuid'];
      this.threadUuid = params['threadUuid'];
      if (this.conversationUuid && this.conversationUuid !== '-') {
        this.logger.debug('Wolfram Conversation UUID given, load Wolfram conversation', this.conversationUuid);
        this.loadWolframConversation();
      } else {
        this.conversationUuid = '-';
      }
      if (this.threadUuid && this.threadUuid !== '-') {
        this.logger.debug('LangChain Thread UUID given, load LangChain conversation', this.threadUuid);
        //this.loadLangChainConversation();
      } else {
        this.threadUuid = '-';
      }
    });
  }

  ngAfterViewInit() {
    if (this.conversationBoxElement) {
      this.observer.observe(this.conversationBoxElement.nativeElement, {
        childList: true,
        subtree: true,
      });
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  loadWolframConversation() {
    this.processing$.next(true);
    this.http
      .get<WolframConversation>(this.localStorageService.getWolframUrl() + '/labVLoadConversation',
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: new HttpParams()
            .set('_key', this.wolframKey)
            .set('userID', this.wolframUser)
            .set('conversationID', this.conversationUuid !== '-' ? this.conversationUuid : ''),
        },
      )
      .subscribe((response) => {
        this.logger.debug('Wolfram Conversation: ', response);
        this.chatHistory = response.messages;
        this.structureChatHistory();
        this.processing$.next(false);
        this.scrollToBottom();
        this.setFocusOnInput();
      });
  }

  sendMessage() {
    if (this.userMessage == "\\restart") {
      this.router.navigate([appRoutesNames.ASSISTANT]).then(() => {
        window.location.reload();
      });
    } else if (this.userMessage == "\\clear") {
      this.chatHistory = [];
      this.chatHistory$.next(this.chatHistory);
      this.userMessage = '';
    } else if (this.isDocumentMode$.value) {
      this.sendMessageToLangChain();
    } else {
      this.sendMessageToWolfram();
    }
  }

  sendMessageToWolfram() {
    this.processing$.next(true);
    const userMessageContent = this.userMessage + ' ||' + Date().toLocaleString() + '||';
    const timestamp = new Date().toLocaleString();
    this.chatHistory.push({
      Role: 'User',
      Content: this.userMessage,
      Timestamp: timestamp,
    });
    this.userMessage = '';
    this.chatHistory$.next(this.chatHistory);
    this.scrollToBottom();
    const requestBody = {
      input: userMessageContent,
      userID: 'devlabv1',
      model: 'labv-openai-gpt4o',
      conversationID: this.conversationUuid !== '-' ? this.conversationUuid : '',
      accessToken: this.localStorageService.getAuthentication(),
    };

    this.http
      .post<WolframConversation>(
        this.localStorageService.getWolframUrl() + '/labVChat',
        requestBody, {
          headers: {
            'Content-Type': 'application/json',
          },
          params: new HttpParams().set('_key', 'wolfram123'),
        },
      )
      .subscribe(
        (response) => {
          this.logger.debug('Wolfram Message Response: ', response);
          let newMessages = false;
          response.messages.forEach((message) => {
            if (newMessages) {
              this.chatHistory.push(message);
            } else if (message.Role === 'User' && message.Content === userMessageContent) {
              newMessages = true;
            }
          });
          if (this.conversationUuid === '-') {
            this.conversationUuid = response.conversationID;
            this.location.go('/assistant/' + this.conversationUuid + '/' + this.threadUuid);
          }
          this.structureChatHistory();
          this.processing$.next(false);
          this.scrollToBottom();
          this.setFocusOnInput();
        },
      );
  }

  loadLangChainConversation() {
    this.http
      .get<LangChainThreadDto[]>(
        environment.langchainUrl + '/conversations' + (this.threadUuid !== '-' ? '/' + this.threadUuid : ''),
        {
          headers: {
            Authorization: 'Bearer ' + this.localStorageService.getAuthentication(),
          },
        },
      )
      .subscribe((thread: LangChainThreadDto[]) => {
        this.logger.debug('Loaded conversation:', thread);
        if (thread && thread.length > 0) {
          const message = thread[0];
          this.chatHistory.push({
            Role: 'Assistant',
            Content: message.generation,
            Timestamp: message.create_date,
            Documents: message.sources,
            //web_sources: message.web_sources,
          });
          this.structureChatHistory();
          this.processing$.next(false);
          this.scrollToBottom();
          this.setFocusOnInput();
        }
        /*for (const message of thread) {
          this.chatHistory.unshift({
            Role: 'Assistant',
            Content: message.generation,
            Timestamp: message.create_date,
            Documents: message.sources,
            //web_sources: message.web_sources,
          });
          this.chatHistory.unshift({
            Role: 'User',
            Content: message.question,
            Timestamp: message.create_date,
          });
        }
        this.structureChatHistory();
        */
      });
  }

  sendMessageToLangChain() {
    this.processing$.next(true);
    const message = this.userMessage;
    this.userMessage = '';
    this.chatHistory.push({
      Role: 'User',
      Content: message,
      Timestamp: new Date().toLocaleString(),
    });
    this.chatHistory$.next(this.chatHistory);

    const url =
      environment.langchainUrl +
      '/chats/get_response_stream?' +
      (this.threadUuid !== '-' ? 'conversation_uuid=' + this.threadUuid : '');

    const source = new SSE(url, {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.localStorageService.getAuthentication() },
      payload: JSON.stringify({ query: message }),
    });

    let response = '';

    source.addEventListener('open', (e: StreamEvent) => {
      this.logger.debug('Stream opened', e);
      if (this.threadUuid === '-') {
        this.threadUuid = e.headers['conversation_uuid'];
        this.location.go('/assistant/' + this.conversationUuid + '/' + this.threadUuid);
      }
    });

    source.addEventListener('token', (e: StreamEvent) => {
      response += e.data;
    });

    source.addEventListener('readystatechange', (e: _ReadyStateEvent) => {
      if (e.readyState === 2) {
        this.loadLangChainConversation();
      }
    });

    source.stream();
  }

  structureChatHistory() {
    this.chatHistory.forEach((message, index) => {
      const match = message.Content.match(/TOOLCALL:[\s\S]*?ENDARGUMENTS/);
      if (match) {
        message.Content = message.Content.replace(/TOOLCALL:[\s\S]*?ENDARGUMENTS/, '').trim();
        this.logger.log('TOOLCALL: ', match[0]);
      }
      const splitMessage = message.Content.split('ENDTOOLCALL\nRESULT:');
      if (splitMessage.length > 1) {
        message.Content = splitMessage[0].trim();
        message.Role = 'System';
        if (message.ToolResponse) {
          this.chatHistory[index - 1].ToolResponse = message.ToolResponse;
        }
        this.logger.log('ENDTOOLCALL RESULT: ', splitMessage[1]);
      }

      const regex = /\(\|(.*?)\|\)/gm;
      let result;
      this.logger.debug('Message content: ', message.Content);
      message.Suggestions = [];
      while ((result = regex.exec(message.Content)) !== null) {
        this.logger.debug('Split content: ', result);
        message.Suggestions.push(result[1]);
      }
      message.Content = message.Content.replace(regex, '');


      message.Content = message.Content.replace(this.dateSectionRegExp, '');

      message.Content = message.Content.trim();
    });
    this.chatHistory$.next([...this.chatHistory]);
    this.logger.debug('Structured History: ', this.chatHistory);
  }

  openModal(id: string) {
    const dialog: HTMLDialogElement | null = document.getElementById(id) as HTMLDialogElement;
    if (dialog) {
      dialog.showModal();
    }
  }

  @HostListener('document:keydown.control.k', ['$event'])
  toggleAiMode() {
    const newMode = !this.isDocumentMode$.value;
    this.isDocumentMode$.next(newMode);
    this.logger.debug('Document mode: ', newMode);
  }

  scrollToBottom() {
    this.conversationBoxElement.nativeElement.scrollTo({
      top: this.conversationBoxElement.nativeElement.scrollHeight,
      behavior: 'smooth',
    });
  }

  setFocusOnInput() {
    this.messageInputElement.nativeElement.focus();
  }

  toggleLineClamp(event: MouseEvent | KeyboardEvent) {
    const element = event.target as HTMLElement;
    element.classList.toggle('line-clamp-5');
  }

  normalizeAndEncode(param: string) {
    return encodeURI(param.normalize('NFC'));
  }

  stringEscapeBackslashes(content: string): string {
    return content ? content.replace(/\\/g,'\\\\') : content;
  }
}
