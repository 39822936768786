import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

const AUTHENTICATION = 'authentication';
const LANGUAGE = 'language';
const THEME = 'theme';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private platformId = inject(PLATFORM_ID);
  private theme = new Subject<string>();
  private language = new Subject<string>();
  private wolframUrl = new Subject<string>();



  set(key: string, value: unknown): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  setLanguage(value: string) {
    this.language.next(value);
    this.set(LANGUAGE, value);
  }

  setTheme(value: string) {
    this.theme.next(value);
    this.set(THEME, value);
  }

  setWolframUrl(value: string) {
    this.wolframUrl.next(value);
    this.set('wolframUrl', value);
  }

  get(key: string): unknown {
    if (isPlatformBrowser(this.platformId)) {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    }
    return null;
  }

  getLanguage(): string {
    return this.get(LANGUAGE) as string;
  }

  getTheme(): string {
    return (
      this.get(THEME)
        ? this.get(THEME)
        : window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
    ) as string;
  }

  getThemeObservable(): Observable<string> {
    return this.theme.asObservable();
  }

  getWolframUrl(): string {
    const wolframUrl = this.get('wolframUrl') as string;
    if (!wolframUrl) {
      return environment.wolframUrl;
    }
    return wolframUrl;
  }

  remove(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    }
  }

  removeAuthentication() {
    this.remove(AUTHENTICATION);
  }

  removeLanguage() {
    this.remove(LANGUAGE);
  }

  removeTheme() {
    this.theme.next('');
    this.remove(THEME);
  }

  clear(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
  }

  getAuthentication(): string {
    return this.get(AUTHENTICATION) as string;
  }

  setAuthentication(value: string) {
    this.set(AUTHENTICATION, value);
  }
}
