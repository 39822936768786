<div class="grid grid-cols-1 lg:grid-cols-4">
  <div></div>
  <div class="col-span-2 card mb-4 bg-base-100 shadow-xl">
    <div class="card-body pb-3">
      <input type="checkbox" class="toggle absolute top-3 left-3"
             (change)="toggleAiMode()"
             checked="{{ isDocumentMode$.value ? 'checked' : '' }}"
      />
      <div class="text-center">
        <app-icon icon="sparkles" [width]="24" [height]="24"></app-icon>
        <h2 class="text-xl font-medium leading-5">LabV-AI</h2>
        <h3 class="text-gray-400 mt-0 text-sm leading-3"><small>Preview</small></h3>
      </div>
    </div>
    <hr>
    <div class="card-body pb-0">
      <div #conversationBox class="h-max">
        @if (chatHistory$ | async; as chatHistory) {
          @for (message of chatHistory; track $index) {
            @if (message.Role === 'System') {
            } @else {
              <div class="flex w-full pb-3 assistant-chat">
                <div class="pr-2">
                  <app-avatar src="{{message.Role === 'User' ? userAvatarUrl : assistantAvatarUrl}}"></app-avatar>
                </div>
                <div>
                  <small>{{ message.Role === 'User' ? ('assistant-you' | transloco) : 'LabV-Assistant' }}</small>
                  <div class="w-full">
                    <div
                      class="{{ (message.Role === 'User' ? 'bg-primary border-primary text-white' : 'bg-gray-100 border-gray-200')
                    + ' card border-1 mb-2 w-full overflow-hidden inline-grid' }}">
                      <div class="card-body flex flex-col text-sm font-light p-3 w-full overflow-x-auto">
                        <markdown katex [katexOptions]="optionsKatex" ngPreserveWhitespaces>
                          {{ message.Content }}
                        </markdown>
                      </div>
                    </div>
                  </div>
                  @if (message.ToolResponse || (message.Documents && message.Documents.length > 0)) {
                    <button
                      class="btn btn-sm btn-outline btn-sources border-1 border-gray-200"
                      (click)="openModal('sources_' + $index)"
                    >
                      {{ 'assistant-sources' | transloco }}
                      @if (message.ToolResponse) {
                        <app-icon icon="wolfram"></app-icon>
                      }
                      @if (message.Documents) {
                        <app-icon icon="document"></app-icon>
                      }
                    </button>
                    <dialog [attr.id]="'sources_' + $index" class="modal">
                      <div class="modal-box max-w-5xl">
                        <form method="dialog">
                          <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                        </form>
                        <h3 class="text-lg font-bold">
                          {{ 'assistant-sources' | transloco }}
                        </h3>
                        @if (message.ToolResponse) {
                          <div class="mt-2 p-2 border-1 border-gray-200">
                            <h4 class="font-bold">{{ 'assistant-wolfram-response' | transloco }}</h4>
                            <ngx-json-viewer
                              [json]="message.ToolResponse"
                              [depth]="2"
                              class="overflow-x-scroll inline-grid"
                            ></ngx-json-viewer>
                          </div>
                        }
                        @if (message.Documents) {
                          @for (document of message.Documents; track $index; ) {
                            <div class="mt-2 p-2 border-1 border-gray-200">
                              <h4 class="font-bold">
                                <a
                                  href="/docs/{{ document.metadata._collection_name }}/{{normalizeAndEncode(document.metadata.fileName!)}}#page={{ document.metadata.page! + 1 }}"
                                  target="_blank"
                                  title="{{ document.metadata.fileName }}, {{'assistant-document-page' | transloco}} {{ document.metadata.page! + 1 }}"
                                >
                                  {{ document.metadata.fileName }}
                                </a>
                                <small
                                  class="font-normal">({{ 'assistant-document-page' |transloco }} {{ document.metadata.page! + 1 }}
                                  )</small>
                              </h4>
                              <div
                                class="text-xs italic line-clamp-5 cursor-pointer whitespace-pre-wrap"
                                (click)="toggleLineClamp($event)"
                                (keyup)="toggleLineClamp($event)"
                                tabindex="0"
                              >
                                  {{ document.page_content }}
                              </div>
                            </div>
                          }
                        }
                      </div>
                      <form method="dialog" class="modal-backdrop">
                        <button>close</button>
                      </form>
                    </dialog>
                  }
                </div>
              </div>
            }
          } @empty {
            <div class="text-center">
              <p class="text-gray-400 text-xl font-light pb-4">
                {{ 'assistant-start' | transloco }}
              </p>
            </div>
          }
          @if (processing$.value) {
            <div class="flex w-full pb-2">
              <div class="pr-2">
                <app-avatar src="{{assistantAvatarUrl}}"></app-avatar>
              </div>
              <div>
                <small>LabV-Assistant</small>
                <div
                  class="bg-gray-100 card w-full mb-2">
                  <div class="card-body flex-grow text-sm font-light p-3">
                    <span class="loading loading-dots loading-md m-auto"></span>
                  </div>
                </div>
              </div>
            </div>
          }
        }
      </div>
    </div>
    <hr>
    <div class="card-body pb-4 pt-4">
      <div class="form-control flex items-center">
        <div class="input-group input-group-sm flex items-center space-x-2 w-full">
          <input [disabled]="processing$.value" class="input input-bordered input-sm flex-grow" type="text"
                 placeholder="{{'assistant-type' | transloco }}"
                 [(ngModel)]="userMessage"
                 (keyup.enter)="sendMessage()"
                 #messageInput
          >
          <button [disabled]="processing$.value" class="btn btn-primary btn-sm p-0 h-8 w-8"
                  (click)="sendMessage()">
            <app-icon icon="send" fill="#ffffff"></app-icon>
          </button>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</div>
